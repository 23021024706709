const projects = [
  {
      id: 1,
      title: 'Currently',
      description: 'Senior Real-Time Graphics Artist/Developer @ Apple (Contract). Read more...',
      longDescription:
          'I work across fashion, tech, and entertainment using tools like TouchDesigner and Unreal Engine. From creating interactive installations for La Mer to real-time effects for Doja Cat & Travis Scott, I focus on pushing the boundaries of interactive media.',
      highlights: [
          'La Mer: Interactive installation in Singapore.',
          'Travis Scott: Real-time visuals for his SNL performance.',
          'Doja Cat: Developed immersive visuals for live shows.',
      ],
      shader: 'currentlyShaders',
  },
  {
      id: 2,
      title: 'Tool-Kit',
      description: 'The essential tools that shape my creative work. Read more....',
      longDescription: 'Here are the tools I work with to bring ideas to life across interactive and immersive experiences:',
      highlights: [
          'TouchDesigner',
          'Unreal Engine',
          'Notch',
          'GLSL',
          'Python',
          'Houdini',
          'Rhino CAD',
          'Three.js',
          'React'
      ],
      shader: 'foundations',
  },
  {
      id: 3,
      title: 'Music First',
      description: 'From co-founding a band to audio-visual art. Read more...',
      longDescription:
          'Before focusing on creative technology, I co-founded argonaut&wasp, touring internationally and achieving over 20 million streams. I later applied my music experience to projects combining sound with real-time visuals.',
      highlights: [
          
      ],
      shader: 'beatsToBytes',
  },
  {
      id: 4,
      title: 'DIY Mindset',
      description: 'Advancing interactive media in music, sports, and live events. Read more...',
      longDescription:
          'I found my way through constant self-learning. I taught myself coding, 3D modeling, and real-time engines like Unity, Unreal Engine and TouchDesigner. What began as a way to merge sound and visuals became an obsession with interactive media -- leading to work across entertainment, from music to sports... ',
      highlights: [
  
          
           'Manchester City',
           'The Knicks',
           'The Rangers'
      ],
      shader: 'interactive',
  },
  {
      id: 5,
      title: 'Mastering Shaders',
      description: 'Creating real-time visual effects through shader programming. Read more...',
      longDescription:
          'I specialize in shader programming, producing complex visual effects for projects such as Zedd’s "Lucky" and La Mer’s Singapore installation. Additionally, I designed the interface for Hume AI’s empathetic avatar, enabling real-time AI interactions.',
      highlights: [
          'Zedd (UMG x Intel): Shader programming for "Lucky".',  
          'La Mer: Developed shaders for the Singapore installation.',
          'Hume AI: Designed the visual interface for the AI avatar, EVI.'
      ],
      shader: 'wave',
  },
  {
      id: 6,
      title: 'Impact',
      description: 'Leading creative and technical projects. Click for details...',
      longDescription:
          'My work spans from interactive games to immersive audio-visual installations, live events, and web experiences. I take immense pride in the artistry intrinsic to multimedia and multi-medium expression.',
      highlights: [
          'Various franchise Sports Teams: Developed interactive games for players and fans.',
          'Hyperspace: Directed technical aspects of immersive experiences in Saudi Arabia.',
          'Hume AI: Developed the empathetic AI avatar, EVI.',
      ],
      shader: 'masteringShaders',
  },
];

export default projects;
